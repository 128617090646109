<template>
  <!-- 促销方案 -->
  <div class="special-scheme">
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>选择促销类型</span>
      </div>
      <div class="main">
        <el-scrollbar>
          <!-- 特价区域 -->
          <div class="special" title="【特价】">
            <div class="title">【特价】</div>
            <div class="list">
              <div
                class="item orange"
                v-for="item in specialList.special"
                :key="item.id"
                @click="openSchemeDetail(item.id, item.title)"
              >
                <div class="left">
                  <div class="content">
                    <div style="font-size: 14px; color: #333333">{{ item.content }}</div>
                    <div class="tag">{{ item.tag }}</div>
                  </div>
                </div>
                <div class="right">
                  <div class="text">{{ item.title }}</div>
                </div>
              </div>
            </div>
          </div>
          <!-- 折扣区域 -->
          <div class="discount" title="【折扣】">
            <div class="title">【折扣】</div>
            <div class="list">
              <div
                class="item blue"
                v-for="item in specialList.discount"
                :key="item.id"
                @click="openSchemeDetail(item.id, item.title)"
              >
                <div class="left">
                  <div class="content">
                    <div style="font-size: 14px; color: #333333">{{ item.content }}</div>
                    <div class="tag">{{ item.tag }}</div>
                  </div>
                </div>
                <div class="right">
                  <div class="text">{{ item.title }}</div>
                </div>
              </div>
            </div>
          </div>
          <!-- 满赠/满减区域 -->
          <div class="presenter" title="【满赠/满减】">
            <div class="title">【满赠/满减】</div>
            <div class="list">
              <div
                class="item pink"
                v-for="item in specialList.presenter"
                :key="item.id"
                @click="openSchemeDetail(item.id, item.title)"
              >
                <div class="left">
                  <div class="content">
                    <div style="font-size: 14px; color: #333333">{{ item.content }}</div>
                    <div class="tag">{{ item.tag }}</div>
                  </div>
                </div>
                <div class="right">
                  <div class="text">{{ item.title }}</div>
                </div>
              </div>
            </div>
          </div>
          <!-- 满赠/满减区域 -->
          <div class="presenter" title="【组合】">
            <div class="title">【组合】</div>
            <div class="list">
              <div
                class="item green"
                v-for="item in specialList.group"
                :key="item.id"
                @click="openSchemeDetail(item.id, item.title)"
              >
                <div class="left">
                  <div class="content">
                    <div style="font-size: 14px; color: #333333">{{ item.content }}</div>
                    <div class="tag">{{ item.tag }}</div>
                  </div>
                </div>
                <div class="right">
                  <div class="text">{{ item.title }}</div>
                </div>
              </div>
            </div>
          </div>
        </el-scrollbar>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  name: "SpecialScheme",
  data() {
    return {
      //促销方案
      specialList: {
        special: [
          {
            id: "0",
            title: "特价促销",
            content: "购买指定商品,享价格优惠",
            tag: "如: 商品限时特价99元",
          },
          {
            id: "1",
            title: "数量特价",
            content: "指定商品购买不同数量,享不同力度优惠价格",
            tag: "如: 商品买一件90元,买两件170元",
          },
          {
            id: "2",
            title: "固定组合",
            content: "购买一定组合方式的商品,享优惠价格",
            tag: "如: 固定组合99元",
          },
          {
            id: "3",
            title: "动态组合",
            content: "购买一定动态组合方式的商品,享优惠价格",
            tag: "如: 99元任选5件",
          },
        ],
        discount: [
          {
            id: "4",
            title: "折扣优惠",
            content: "购买指定范围内商品,拿对应优惠折扣",
            tag: "如: 全场9折,食品类食品9折",
          },
          {
            id: "5",
            title: "数量折扣",
            content: "指定商品购买不同的数量,拿不同力度的优惠折扣",
            tag: "如: 买2件9折,3件8.5折",
          },
          {
            id: "6",
            title: "金额折扣",
            content: "购买商品满指定金额,拿一定优惠折扣",
            tag: "如: 满100打9折",
          },
          {
            id: "11",
            title: "预订优惠",
            content: "提前预订指定天数,拿一定优惠折扣",
            tag: "如: 提前1天打9折",
          },
        ],
        presenter: [
          {
            id: "7",
            title: "金额满减",
            content: "指定商品满指定金额,享一定金额减免",
            tag: "如: 指定商品满200减50元",
          },
          {
            id: "8",
            title: "买满赠送",
            content: "购买指定商品满一定数量或金额,获得赠品或优惠",
            tag: "如: 商品买一送一,第二件半价,加购等",
          },
          {
            id: "9",
            title: "充值免单",
            content: "消费N元,充值消费金额N倍免单/折扣",
            tag: "如: 消费60元,充值100元,免单60元",
          },
          {
            id: "12",
            title: "额外赠送",
            content: "购买指定商品满一定金额,获得赠品",
            tag: "如: 消费50元,赠送一个袋子等",
          },
        ],
        group: [
          {
            id: "10",
            title: "组合优惠",
            content: "指定多个组合组成优惠方案",
            tag: "如A组合选N个,B组合选N个,组成优惠、特价、折扣组合",
          },
        ],
      },
    };
  },
  methods: {
    //打开促销方案明细
    openSchemeDetail(id) {
      //判断跳转到该方案页面
      let routerName;
      if (id === "0") routerName = "SpecialPromo";
      if (id === "1") routerName = "QuantitySpecial";
      if (id === "2") routerName = "FixedGroup";
      if (id === "3") routerName = "DynamicGroup";
      if (id === "4") routerName = "Discount";
      if (id === "5") routerName = "AmountDiscount";
      if (id === "6") routerName = "MoneyDiscount";
      if (id === "7") routerName = "MoneyFullDiscount";
      if (id === "8") routerName = "FulnessFullGift";
      if (id === "9") routerName = "RefillFree";
      if (id === "10") routerName = "GroupDiscounts";
      if (id === "11") routerName = "ResetDiscounts";
      if (id === "12") routerName = "BonusGift";
      console.log("routerName", routerName);
      this.$router.push({
        name: routerName,
        query: {
          type: "add",
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.special-scheme {
  padding: 10px;
  background-color: #f3f3f3;
  height: calc(100vh - 90px);
  overflow-y: auto;
  ::v-deep .el-card {
    height: 100%;
  }
  .box-card {
    .main {
      padding-bottom: 60px;
      .el-scrollbar {
        height: 75vh;
        ::v-deep .el-scrollbar__wrap {
          overflow-x: hidden;
        }
      }
      //标题
      .title {
        text-align: left;
        font-weight: 700;
      }
      .discount {
        margin: 15px 0;
      }
      .list {
        width: 100%;
        display: flex;
        margin-top: 15px;
        margin-bottom: 20px;
        flex-wrap: wrap;
        .item {
          display: flex;
          width: 320px;
          height: 105px;
          margin: 0 10px;
          box-shadow: 5px 10px 5px #ededf1;
          &.pink {
            background-color: #ff3e54;
          }
          &.orange {
            background-color: #ff8a35;
          }
          &.blue {
            background-color: #338df7;
          }
          &.green {
            background-color: #12cc9f;
          }
          .left {
            margin: 15px;
            width: 240px;
            padding: 2px 0;
            background-image: url("~@/assets/images/SpecialScheme.png");
            background-size: 100% 100%;
            .content {
              div {
                font-size: 12px;
                padding: 5px 10px;
                font-weight: 700;
                &.tag {
                  color: #ccc;
                  font-weight: 400;
                }
              }
            }
          }
          .right {
            width: 60px;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: start;
            .text {
              width: 45px;
              font-size: 22px;
              font-weight: 700;
              color: #fff;
              text-align: justify;
              text-align-last: justify;
            }
          }
        }
      }
    }
  }
}
::v-deep .el-card__header {
  display: flex;
  align-items: center;
  padding-left: 10px;
}
</style>
